import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';

import useHasMounted from 'context/useHasMounted';
import useReferral from 'components/strombestilling/helpers/useReferral';
import ListStyling, { ListWithIconsStyling } from 'libs/SharedStyling';
import { stromPagesBaseUrl } from 'components/strombestilling/helpers/usePageNavigation';
import Link from 'components/Link';
import { track } from 'context/AnalyticsProvider';
import {
	getCorrectPowerPath,
	getUrl,
	powerProductPrimarySegment,
} from 'libs/content';
import ProductWrapper from '../ProductWrapper';
import BasicContent from 'parts/basic-content/BasicContent';
import Button from 'components/forms/Button';
import SanitizeHtml from 'libs/SanitizeHtml';
import useProductInfo from 'components/strombestilling/helpers/useProductInfo';

//#region Styling

const Price = styled.div`
	text-align: center;
	margin-top: 20px;
`;

const Selectors = styled.div`
	margin-top: 20px !important;
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-end;
	${p =>
		p.theme.media.large(css`
			margin-top: 25px !important;
		`)}
	a {
		margin-top: 20px;
	}
	button {
		justify-content: center;
	}
	${p =>
		p.perrow === 1 &&
		css`
			${p =>
				p.theme.media.medium(css`
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					button {
						min-width: 280px;
						justify-content: center;
					}
					a {
						margin: 0 0 0 20px;
					}
				`)}
		`}
`;

const TermsStyle = () => css`
	flex-grow: 1;
	.terms,
	ul {
		width: 100%;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		${p =>
			p.perrow === 1 &&
			css`
				${p =>
					p.theme.media.large(css`
						column-count: 2;
						column-gap: 120px;
						li {
							break-inside: avoid;
						}
					`)}
			`}
	}
`;

const TermsWrapper = styled.div`
	${TermsStyle}
	${ListStyling}
	${ListWithIconsStyling}
	.component__basic-content__width-limit {
		padding: 0;
		margin-top: 0;
		ul {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			margin-top: 0 !important;
		}
	}

	/* If the html content contains ul and p then set column count to 2 */
	${p =>
		p.$htmlelements === 'ul and p' &&
		p.perrow === 1 &&
		css`
			.component__title-and-text .sanitized {
				${p =>
					p.theme.media.large(css`
						column-count: 2;
						column-gap: 120px;
						ul,
						.terms {
							column-count: unset;
							column-gap: unset;
							margin-bottom: 20px;
						}
					`)}
			}
		`}

	${p =>
		p.$htmlelements !== 'ul and p' &&
		css`
			ul {
				margin-bottom: 0 !important;
			}
		`}
`;

const NotAvailable = styled.p`
	text-align: center;
	font-size: 17px;
	font-style: italic;
	margin: 20px 0 0 0;
`;

const Disclaimer = styled.p`
	font-size: 14px;
	line-height: 22px;
	color: ${p => p.theme.colors.grey700};
	margin: 20px 0 0;
`;

/**
 * Represents a power product
 * @param {string} highlighted - Whether to product is highlighted or not
 * @param {object} customButton - The custom button for the component.
 * @param {boolean} showPrice - Whether to show the price or not
 * @param {integer} perrow - How many products per row
 * @param {string} newproduct - Whether the product should show a badge with the text "New" or not
 * @param {boolean} transitions - Whether the product should have transitions or not
 * @param {boolean} readMore - Whether the product should show a "Read more" text link
 * @param {object} location - The location object
 * @param {function} onClick - The onClick function for the button
 * @param {...object} rest - Rest of the properties for the component
 * @returns {JSX.Element} The power product component
 */
export default function PowerProduct({
	highlighted = 'false',
	customButton = {},
	showPrice = false,
	perrow = 3,
	newproduct = 'false',
	transitions = false,
	readMore = true,
	location = {},
	onClick = () => {},
	...rest
}) {
	const hasMounted = useHasMounted();
	const {
		loading: loadingReferral,
		referral,
		termsWithCustomTerm,
	} = useReferral(location);
	const { productInfo: product, loading } = useProductInfo(rest);

	const productsegment = powerProductPrimarySegment(product?.productsegment);

	const [termsHtml, setTermsHtml] = useState(
		product?.termsHtml || product?.terms
	);

	// Process the html content with the custom term if the referral is active
	useEffect(() => {
		if (!loadingReferral && referral?.status === 'AKTIV') {
			setTermsHtml(
				termsWithCustomTerm(
					product?.termsHtml,
					'500 kr rabatt på strømregningen.'
				)
			);
		} else {
			setTermsHtml(product?.termsHtml || product?.terms);
		}
		//eslint-disable-next-line
	}, [loadingReferral, referral?.status, product?.termsHtml]);

	const isLandbrukBedrift =
		product?.productsegment?.length > 0 &&
		['Landbruk', 'Bedrift'].every(segment =>
			product?.productsegment?.includes(segment)
		);

	// Set default button link to default strømbestilling
	let buttonLink = `${stromPagesBaseUrl}?produkt=${product?.tariff}`;
	let buttonText = `Bestill ${product?.name}`;
	let readMoreLink = true;

	// If product is set to lead to subpage with typeform instead of strømbestillling
	if (
		product?.orderButton === 'En annen side' &&
		product?.orderFormPage?.slug
	) {
		buttonLink = getUrl(product?.orderFormPage?.slug);
	}

	// If product is showing terms from master and has no manual price info then button should lead to power-product-page
	if (
		!product?.showpricesfrommaster &&
		product?.terms === null &&
		product?.productsegment?.includes('Privat') &&
		// Make sure the product segment is not bedrift and landbruk
		!isLandbrukBedrift
	) {
		buttonLink = getCorrectPowerPath(productsegment, product?.slug);
		buttonText = 'Les mer om produktet';
		readMoreLink = false;
	}

	// If custom slug is set then button should use this
	if (customButton?.slug) {
		buttonLink = stromPagesBaseUrl + customButton?.slug;
		buttonText = customButton?.text || `Bestill ${product?.name}`;
		readMoreLink = readMore || product?.readMore || false;
	}

	if (!product?.termsHtml && (loading || loadingReferral)) return;

	return (
		<ProductWrapper
			id={`product-${product?.name}`}
			perrow={perrow}
			showpriceterms={!!product?.showpricesfrommaster && product?.tariff}
			newproduct={newproduct}
			highlighted={
				highlighted === 'true' &&
				product?.tariff === 'standardprodukt-inn'
					? 'true'
					: 'false'
			}
			doanimate={transitions}
			direction="down"
			delay={
				(product?.componentindex === 0 && 0) ||
				(product?.productindex === 0 && 2.5) ||
				(product?.productindex && 2.5 * product?.productindex) ||
				2.5
			}
			{...product}
			header={
				<>
					<h3>{product?.title || product?.name}</h3>

					{(product?.description && (
						<SanitizeHtml html={product?.description} as="p" />
					)) ||
						(product.moreText && <p>{product.moreText}</p>)}
				</>
			}>
			{!loadingReferral && !!termsHtml && (
				<TermsWrapper
					$checkicon="true"
					perrow={perrow}
					$htmlelements={checkHtmlContent(
						termsHtml?.childMarkdownRemark?.html,
						hasMounted
					)}>
					<BasicContent
						title={undefined}
						spacing={{ top: 'none', bottom: 'none' }}
						settings={['Fullbredde', 'Skjul tittel og intro']}>
						<SanitizeHtml html={termsHtml} />
					</BasicContent>
				</TermsWrapper>
			)}

			{showPrice && product?.price && (
				<Price>
					<span>{product.price}</span>
				</Price>
			)}

			<Selectors perrow={perrow}>
				{/* Button */}
				{product.orderButton !== 'Ikke vis bestill-knapp' && (
					<Button
						data-cy="order-button"
						title={customButton?.text || `Bestill ${product.name}`}
						onClick={() => {
							if (onClick) onClick();
							track('Button Clicked', {
								label:
									customButton?.text ||
									(product.name &&
										`Bestill ${product.name}`) ||
									`Bestill`,
								category: window.location.pathname,
							});
							if (buttonLink) navigate(getUrl(buttonLink));
						}}>
						{buttonText}
					</Button>
				)}

				{/* Price and popup-link */}
				{product.orderButton !== 'Ikke vis bestill-knapp' &&
					readMore &&
					readMoreLink && (
						<Link
							title={`Les mer om ${product?.name}`}
							to={getCorrectPowerPath(
								productsegment,
								product.slug
							)}>
							Les mer om {product?.name}
						</Link>
					)}

				{/* If product not available to order */}
				{product.orderButton === 'Ikke vis bestill-knapp' && (
					<NotAvailable>
						Denne avtalen er ikke lengre tilgjengelig for salg.
					</NotAvailable>
				)}
			</Selectors>

			{product?.footnote && (
				<Disclaimer>
					<SanitizeHtml html={product?.footnote} as="span" />
				</Disclaimer>
			)}
		</ProductWrapper>
	);
}

/**
 * Check if the html content contains ul, p or both, if neither return the first element tag name
 * @param {string} html - The html content to check
 * @param {boolean} hasMounted - Whether the component has mounted or not
 * @returns {string} - The elements that the html content contains
 */
function checkHtmlContent(html, hasMounted) {
	if (!hasMounted) return '';

	const parser = new DOMParser();
	const doc = parser.parseFromString(html, 'text/html');

	const containsParagraph = doc.getElementsByTagName('p').length > 0;
	const containsUnorderedList = doc.getElementsByTagName('ul').length > 0;

	if (containsParagraph && containsUnorderedList) {
		return 'ul and p';
	} else if (containsParagraph) {
		return 'p';
	} else if (containsUnorderedList) {
		return 'ul';
	} else {
		const firstElement = doc.body.firstElementChild;
		return firstElement ? firstElement.tagName.toLowerCase() : 'null';
	}
}
